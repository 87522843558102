
import {defineComponent, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {TypeResources} from "@/models";
import {useForm, useField} from 'vee-validate';
import {ucFirst} from '@/helpers/firstRegister';
import List from "@/views/components/directory/list.vue";

export default defineComponent({
	emits: ["dialog-closed", "item-added"],
	props: {
		open: {
			type: Boolean,
			default: false
		}
	},
	components: {
		List
	},
	setup(_, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const {handleSubmit, handleReset, values} = useForm();

		const { value: name, errorMessage: nameError } = useField<string>('name', 'required');
		const { value: comment, errorMessage: commentError } = useField<string>('comment');

		const avatar = ref("");

		const description_pattern = ref({} as {[key: string]: any});
		const descriptionPatternError = ref<string>("");

		const isLoading = ref(false);
		const service = injectOrThrow<TypeResources.TypeResourceService>('TypeResources.TypeResourceService');

		const add = handleSubmit(async () => {
			if(descriptionPatternError.value.length > 0) return;

			isLoading.value = true;

			try {
				await service.add({
					name: values.name,
					comment: values.comment,
					id_enterprise: store.state.globals.enterpriseId,
					avatar: avatar.value,
					description_pattern: description_pattern.value
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.added", { name: ucFirst(t("dictionary.typeResource")) })});
				emit("item-added");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = () => {
			emit('dialog-closed');
			handleReset();
			description_pattern.value = {};
		}

		return {
			t,
			name, nameError,
			comment, commentError,
			avatar,
			description_pattern, descriptionPatternError,
			isLoading, add, closeModal
		}
	}
})
