
import {defineComponent, ref, watch, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {TypeResources} from "@/models";
import {useForm, useField} from 'vee-validate';
import {ucFirst} from '@/helpers/firstRegister';
import List from "@/views/components/directory/list.vue";

export default defineComponent({
	emits: ["dialog-closed", "item-changed"],
	props: {
		open: {
			type: Boolean,
			default: false
		},
		item: {
			type: Object as PropType<TypeResources.TypeResource>,
			required: true
		}
	},
	components: {
		List
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const {handleSubmit, handleReset, setValues, values} = useForm();

		const { value: name, errorMessage: nameError } = useField<string>('name', 'required');
		const { value: comment, errorMessage: commentError } = useField<string>('comment');

		const avatar = ref("");

		const description_pattern = ref({} as {[key: string]: any});
		const descriptionPatternError = ref<string>("");

		const set = () => {
			setValues({
				name: props.item?.name || "",
				comment: props.item?.comment || ""
			});

			avatar.value = props.item.avatar || "";

			if(props.item.description_pattern)
				description_pattern.value = typeof props.item.description_pattern === 'string' ? JSON.parse(props.item.description_pattern) : props.item.description_pattern;
		}

		set();

		watch(() => props.open, () => set());

		const isLoading = ref(false);
		const service = injectOrThrow<TypeResources.TypeResourceService>('TypeResources.TypeResourceService');

		const edit = handleSubmit(async () => {
			if(descriptionPatternError.value.length > 0) return;

			isLoading.value = true;

			try {
				await service.edit({
					id: props.item.id,
					name: values.name,
					comment: values.comment,
					avatar: avatar.value,
					description_pattern: description_pattern.value
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.edited", { name: ucFirst(t("dictionary.typeResource")) })});
				emit("item-changed");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = async () => {
			emit('dialog-closed');
			handleReset();
			description_pattern.value = {};
		};

		return {
			t,
			name, nameError,
			comment, commentError,
			avatar,
			description_pattern, descriptionPatternError,
			isLoading, edit, closeModal
		}
	}
})
